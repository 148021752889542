import { MultiCall } from '@indexed-finance/multicall';
import { BigNumber, Contract, ContractInterface, ethers } from 'ethers';

import { api, paraswap } from '../axios';
import { ChainIdType, Token } from '../interfaces';
import * as ABIS from './abis';
import {
  contractByNetwork,
  MATIC_ADDRESS,
  nativeTokenByNetwork,
} from './constants';

export const getPoolData = async (
  lpToken: string,
  lpBalance: BigNumber,
  libraryNetwork,
  tokenList,
  chainId: ChainIdType,
) => {
  const globalAdapterInstance = loadContract(
    'GlobalAdapter',
    chainId,
    libraryNetwork,
  );

  const { lpPrice, reserves0, reserves1, symbol0, symbol1 } =
    await globalAdapterInstance.getUniLpData(lpToken);

  const usdValue = +fromWei(lpBalance) * +fromWei(lpPrice);
  const token0Info =
    getTokenInfo(symbol0, tokenList) || ({} as Record<string, string>);
  const token1Info =
    getTokenInfo(symbol1, tokenList) || ({} as Record<string, string>);

  if (
    token0Info?.address?.toLowerCase() ===
    '0x831753DD7087CaC61aB5644b308642cc1c33Dc13'.toLowerCase()
  ) {
    token0Info.id = '0x831753DD7087CaC61aB5644b308642cc1c33Dc13';
    token0Info.symbol = 'QUICK(OLD)';
  }
  if (
    token1Info?.address?.toLowerCase() ===
    '0x831753DD7087CaC61aB5644b308642cc1c33Dc13'.toLowerCase()
  ) {
    token1Info.id = '0x831753DD7087CaC61aB5644b308642cc1c33Dc13';
    token1Info.symbol = 'QUICK(OLD)';
  }

  return {
    usdValue,
    reserves: {
      [token0Info.symbol]: +formatDecimals(
        token0Info.symbol,
        +reserves0,
        tokenList,
      ),
      [token1Info.symbol]: +formatDecimals(
        token1Info.symbol,
        +reserves1,
        tokenList,
      ),
    },
  };
};

export async function getBalancesMultiCall(provider_, account, tokenList) {
  if (account === undefined || account === '') return [];

  const provider = provider_;
  const multicall = new MultiCall(provider);
  const tokenAddresses: string[] = [];

  // We exclude the 3CRV because does not have the method balanceOf
  for (const token of tokenList) {
    if (
      token.address !== MATIC_ADDRESS &&
      token.address !== '' &&
      token.symbol !== '3CRV'
    ) {
      tokenAddresses.push(token.address);
    }
  }
  const balances = await multicall.getBalances(tokenAddresses, account);
  return balances;
}

export const getERC20Contract = (address: string, provider: any) => {
  return new Contract(address, ABIS.ERC20, provider);
};

export const loadContract = (
  nameOfContract: string,
  chainId: ChainIdType,
  provider: any,
  addressOfContract?: string,
) => {
  if (
    Object.keys(contractByNetwork[chainId]).filter(
      (name) => name === nameOfContract,
    ).length === 0
  )
    throw Error(
      'LoadingContract: that contract does not exist, verify the name.',
    );

  const { address, abi } = contractByNetwork[chainId][nameOfContract];

  if (address === '')
    return new Contract(addressOfContract, abi as ContractInterface, provider);

  return new Contract(address, abi as ContractInterface, provider);
};

export const expToString = (numIn: number) => {
  let numStr: string = numIn.toString();
  numStr += ''; // To cater to numric entries
  let sign = ''; // To remember the number sign
  if (numStr.charAt(0) === '-') {
    numStr = numStr.substring(1);
    sign = '-';
  } // remove - sign & remember it
  let str = numStr.split(/[eE]/g); // Split numberic string at e or E
  if (str.length < 2) return sign + numStr; // Not an Exponent Number? Exit with orginal Num back
  const power = parseInt(str[1]); // Get Exponent (Power) (could be + or -)
  if (power === 0 || power === -0) return sign + str[0]; // If 0 exponents (i.e. 0|-0|+0) then That's any easy one
  const deciSp = (1.1).toLocaleString().substring(1, 2); // Get Deciaml Separator
  str = str[0].split(deciSp); // Split the Base Number into LH and RH at the decimal point
  let baseRH = str[1] || '', // RH Base part. Make sure we have a RH fraction else ""
    baseLH = str[0]; // LH base part.

  if (power > 0) {
    // ------- Positive Exponents (Process the RH Base Part)
    if (power > baseRH.length) baseRH += '0'.repeat(power - baseRH.length); // Pad with "0" at RH
    baseRH = baseRH.slice(0, power) + deciSp + baseRH.slice(power); // Insert decSep at the correct place into RH base
    if (baseRH.charAt(baseRH.length - 1) === deciSp)
      baseRH = baseRH.slice(0, -1); // If decSep at RH end? => remove it
  } else {
    // ------- Negative Exponents (Process the LH Base Part)
    const num = Math.abs(power) - baseLH.length; // Delta necessary 0's
    if (num > 0) baseLH = '0'.repeat(num) + baseLH; // Pad with "0" at LH
    baseLH = baseLH.slice(0, power) + deciSp + baseLH.slice(power); // Insert "." at the correct place into LH base
    if (baseLH.charAt(0) === deciSp) baseLH = '0' + baseLH; // If decSep at LH most? => add "0"
  }
  return sign + baseLH + baseRH; // Return the long number (with sign)
};

export const formatNumber = (num: number) => {
  if (num > 1) {
    return Number(num).toFixed(2);
  } else {
    return Number(num).toPrecision(2);
  }
};

export const formatRate = (rate: number): number => {
  return parseFloat((rate * 100).toFixed(2));
};

// Token list being called
export const isStableCoin = (symbol, tokenList: Token[]): boolean => {
  const _token = tokenList.filter((t) => t.symbol === symbol && t.isStableCoin);
  return _token.length > 0;
};

export const getAddressToken = (symbol: string, tokenList: Token[]): string => {
  return tokenList.filter((token: Token) => token.symbol === symbol)[0].address;
};

export const checkAllowance = async (
  symbol: string,
  targetSrc: string,
  targetDest: string,
  tokenList: Token[],
  provider: any,
): Promise<BigNumber | null> => {
  const tokenAddress = getAddressToken(symbol, tokenList);
  const instanceToken = getERC20Contract(tokenAddress, provider);

  try {
    return await instanceToken.allowance(targetSrc, targetDest);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const approvalToken = async (
  symbol: string,
  target: string,
  tokenList: Token[],
  provider: any,
): Promise<boolean> => {
  const tokenAddress = getAddressToken(symbol, tokenList);
  const instanceToken = getERC20Contract(tokenAddress, provider);

  try {
    const initialTx = await instanceToken.approve(
      target,
      ethers.constants.MaxUint256,
    );
    await initialTx.wait();
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

// Format decimals value to Wei
export const formatDecimals = (
  token,
  amount: number,
  tokenList: Token[],
): string => {
  return normalize(
    Math.floor(amount * 10 ** getTokenDecimals(token, tokenList)),
  );
};

export const normalize = (num: number): string => {
  return num.toLocaleString('fullwide', { useGrouping: false });
};

export const getTokenDecimals = (
  symbol: string,
  tokenList: Token[],
): number => {
  return tokenList.filter((t) => t.symbol === symbol)[0].decimals;
};

export const formatWei = (
  tokenAddress,
  weiAmount,
  tokenList: Token[],
): number => {
  const address = ethers.utils.getAddress(tokenAddress);
  const { decimals } = tokenList.filter(
    (t) => t.address.toLowerCase() === address.toLowerCase(),
  )[0];

  return weiAmount / 10 ** decimals;
};

export const toBigNumber = (num: string | number): BigNumber =>
  BigNumber.from(num);

export const fromWei = (value: any): string => ethers.utils.formatEther(value);

export const toWei = (value: string) => ethers.utils.parseEther(value);

export const nFormatter = (num, digits) => {
  if (num < 100) return formatNumber(num);
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};

export const getIcon = (symbol: string, tokenList: Token[]): string => {
  let url = '';

  tokenList.forEach((t) => {
    if (t.symbol === symbol) {
      url = t.logoURI;
    }
  });

  return url;
};

export const formatCurrency = (value, minimumFractionDigits = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
  });

  return formatter.format(value);
};

export const formatWeiSymbol = (
  symbol,
  weiAmount,
  tokenList: Token[],
): number => {
  const { decimals } = tokenList.filter((t) => t.symbol === symbol)[0];

  return weiAmount / 10 ** decimals;
};

export const getTokenInfo = (symbol: string, tokenList: Token[]): Token => {
  return tokenList.filter((t) => t.symbol === symbol)[0];
};

export const handleBorrowRate = (
  _case: string,
  _symbol: string,
  globalBorrowData,
) => {
  let borrowAvgRate = 0;
  let rate = 0;

  const globalIndicator = globalBorrowData?.filter(
    (element) => element.symbol === _symbol,
  )[0];

  borrowAvgRate = formatRate(
    (globalIndicator?.aaveData.borrowRate *
      globalIndicator?.aaveData.totalBorrowedUSD +
      globalIndicator?.creamData.borrowRate *
        globalIndicator?.creamData.totalBorrowedUSD) /
      (globalIndicator?.aaveData.totalBorrowedUSD +
        globalIndicator?.creamData.totalBorrowedUSD),
  );

  switch (_case) {
    case 'minorRate':
      rate =
        globalIndicator?.aaveData.borrowRate <
        globalIndicator?.creamData.borrowRate
          ? formatRate(globalIndicator?.aaveData.borrowRate)
          : formatRate(globalIndicator?.creamData.borrowRate);
      break;
    case 'averageRate':
      rate = borrowAvgRate;
      break;
    case 'aaveRate':
      rate = formatRate(globalIndicator?.aaveData.borrowRate);
      break;
    case 'creamRate':
      rate = formatRate(globalIndicator?.creamData.borrowRate);
      break;
    default:
      rate = borrowAvgRate;
      break;
  }
  return rate;
};

export const getExchangePath = (
  protocols: {
    fromTokenAddress: string;
    name: string;
    part: number;
    toTokenAddress: string;
  }[],
): { dex: string; from: string; to: string }[] => {
  const path = [];

  for (let i = 0; i < protocols.length; i++) {
    const _path = protocols[i];

    path.push({
      dex: _path[0].name,
      from: _path[0].fromTokenAddress,
      to: _path[0].toTokenAddress,
    });
  }

  return path;
};

export const isStablecoin = (symbol, tokenList) => {
  const _token = tokenList.filter((t) => t.symbol === symbol && t.isStablecoin);
  return _token.length > 0;
};

export async function getLendingFee(
  market: string,
  provider: ethers.providers.Web3Provider,
  chainId: ChainIdType,
): Promise<ethers.BigNumber> {
  const feeManagerInstance = loadContract('FeeManager', chainId, provider);

  return await feeManagerInstance.getLendingFee(market);
}

export const getParaswapParams = async (
  priceRoute,
  wallet: string,
  account: string,
  chainId: ChainIdType,
) => {
  try {
    const { srcToken, destToken, srcDecimals, destDecimals, srcAmount } =
      priceRoute;

    const deadline = Math.floor(Date.now() / 1000) + 600;

    const POST_DATA = {
      srcToken,
      srcDecimals,
      destToken,
      destDecimals,
      srcAmount,
      userAddress: wallet,
      receiver: wallet,
      txOrigin: account,
      slippage: 500,
      deadline,
      priceRoute,
      positiveSlippageToUser: false,
      partnerAddress: '0xA4BD448BF09081740f9006C8f4D60d9bD2659D89', // Multisig
    };

    const {
      data: { to: swapTarget, data: swapData },
    } = await paraswap.post(`/transactions/${chainId}`, POST_DATA, {
      headers: { 'Content-Type': 'application/json' },
      params: {
        onlyParams: false,
        ignoreChecks: true,
        ignoreGasEstimate: true,
      },
    });

    return { swapTarget, swapData };
  } catch (error) {
    console.error(error.message);
    return { swapTarget: '', swapData: '' };
  }
};

export const getExpectedRate = async (
  src,
  dest,
  srcAmt,
  wallet: string,
  isWei = false,
  chainId: ChainIdType,
  tokenList?: Token[],
): Promise<any> => {
  try {
    const realSrc =
      src === MATIC_ADDRESS
        ? nativeTokenByNetwork[chainId].wrappedAddress
        : src;
    const realDest =
      dest === MATIC_ADDRESS
        ? nativeTokenByNetwork[chainId].wrappedAddress
        : dest;

    if (realSrc === realDest) {
      return {
        result: srcAmt,
        path: [],
        rate: 1,
      };
    }

    const srcSymbol = getTokenSymbol(realSrc, tokenList);
    const destSymbol = getTokenSymbol(realDest, tokenList);

    const srcDecimals = getTokenDecimals(srcSymbol, tokenList);
    const destDecimals = getTokenDecimals(destSymbol, tokenList);

    const { data } = await paraswap.get('/prices', {
      params: {
        srcToken: realSrc,
        srcDecimals,
        destToken: realDest,
        destDecimals,
        amount: isWei
          ? srcAmt
          : String(
              formatDecimals(getTokenSymbol(src, tokenList), srcAmt, tokenList),
            ),
        network: chainId,
        userAddress: wallet,
        maxImpact: 15,
      },
    });

    const impact = (data.priceRoute.srcUSD / data.priceRoute.destUSD - 1) * 100;

    const result = formatWei(realDest, data.priceRoute.destAmount, tokenList);

    const expected = {
      result,
      path: data.priceRoute,
      impact,
      src,
      dest,
      realSrc,
      realDest,
      proxy: data.priceRoute.tokenTransferProxy,
      rate:
        formatWei(realDest, data.priceRoute.destAmount, tokenList) /
        formatWei(realSrc, data.priceRoute.srcAmount, tokenList),
    };

    return expected;
  } catch (error) {
    console.error(error.message);
    return { rate: 0, result: 0, impact: 0, path: '' };
  }
};

export function getTokenSymbol(addr: string, tokenList: any[]) {
  const address = ethers.utils.getAddress(addr);
  return tokenList.filter(
    (t) => t.address.toLowerCase() === address.toLowerCase(),
  )[0].symbol;
}

export async function getYieldOptimizer(
  asset,
  budget,
  period = '1',
  tokensData,
) {
  try {
    const {
      data: { ratios },
    } = await api.get('/strategy/supply', {
      params: {
        asset,
        budget,
        period,
        chain: 'polygon',
      },
    });

    return ratios.filter(
      (r) => (r.platform === 'aave2' || r.platform === 'cream') && r.ratio > 0,
    );
  } catch (error) {
    console.error(error.message);
    // temporal, when optimizer not responding
    return [
      {
        platform: 'aave2',
        ratio: 100,
        supplyRate: tokensData.MATIC.aaveData.supplyRate,
      },
    ];
  }
}

export async function addToken(token: Token): Promise<void> {
  const { ethereum } = window as any;

  try {
    await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals,
          image: token.logoURI,
        },
      },
    });
  } catch (error) {
    console.error(error);
  }
}

const getParamsByChainId = (chainId: number) => {
  switch (chainId) {
    case 250:
      return {
        chainId: ethers.utils.hexValue(chainId),
        chainName: 'Fantom Opera',
        rpcUrls: ['https://rpc.ftm.tools/'],
        blockExplorerUrls: ['https://ftmscan.com/'],
      };
    case 56:
      return {
        chainId: ethers.utils.hexValue(chainId),
        chainName: 'Binance Mainnet',
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com/'],
      };
    case 1284:
      return {
        chainId: ethers.utils.hexValue(chainId),
        chainName: 'Moonbeam',
        rpcUrls: ['https://rpc.api.moonbeam.network/'],
        blockExplorerUrls: ['https://moonscan.io/'],
      };
    default:
      return {
        chainId: ethers.utils.hexValue(chainId),
        chainName: 'Polygon Mainnet',
        rpcUrls: ['https://polygon-rpc.com/'],
        blockExplorerUrls: ['https://polygonscan.com/'],
      };
  }
};

export async function switchChains(
  chainId: ChainIdType,
  close?: () => void,
): Promise<void> {
  const { ethereum } = window as any;

  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: ethers.utils.hexValue(chainId) }],
    });
    if (close) close();
  } catch (error) {
    if (error.code === 4902) {
      await ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [getParamsByChainId(chainId)],
      });
    }
    console.error(error);
  }
}

export const formatValue = (num: number, decimals: number) =>
  num / 10 ** decimals;

export const getTokenIcon = (symbol: string, tokenList: Token[]) => {
  const _token = tokenList.filter((t) => t.symbol === symbol)[0];
  return _token.logoURI;
};

export const getCurveId = (symbol: string): number => {
  switch (symbol) {
    case 'DAI':
      return 0;
    case 'USDC':
      return 1;
    case 'USDT':
      return 2;
    default:
      break;
  }
};

export const renderDepositSymbols = (deposit: any[]) => {
  return deposit.map((item) => item.symbol).join('/');
};

export const isMobile = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
  ) {
    return true;
  }
  return false;
};
